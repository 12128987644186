//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'BusinessCompanyDetails',
    data() {
        return {
            
            BusinessCompanyDetailsList: {
                PhotoAttachmentAr: {

                },
                PhotoAttachmentEn: {

                }
            },
        }
    },
    methods: {
     
       
    },
    created() {
        let self = this;
        self.axios
            .get(process.env.VUE_APP_APIEndPoint + 'CompanyManagement/GetCompanyManagementById/' + self.$route.params?.id)
            .then(response => {
                self.BusinessCompanyDetailsList = response.data.Data;
            });

    },
    updated() {
        //homeNewsSlider();
    },
  
}
